import React from "react"
import styled from "styled-components"
import Layout from "../../components/Layout"
import { BiRightArrowAlt } from "react-icons/bi"
import { Link } from "gatsby"
import { IconContext } from "react-icons"

const FAQdiv = styled.div`
  padding: 50px 5%;
  margin: 0 auto;
  max-width: 1000px;
  h4 {
    font-size: 1.32em;
    margin-bottom: 20px;
  }
  .faqSection {
    padding: 30px 0;
    h5 {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-weight: bold;
      margin-top: 50px;
      margin-bottom: 0.62em;
    }
    .answerSection {
      p {
        padding: 0.62em 0;
      }
      ul li {
        list-style: square;
        margin-left: 1.62em;
      }
    }
  }
`

const BottomLinks = styled.div(
  ({ theme }) => `
    max-width:800px;
    margin:0 auto 100px;
    display:flex;
    flex-flow:row wrap;
    align-items:center;
    justify-content:space-around;
    a{
      background:${theme.colors.primary.brown};
      display:flex;
      flex-flow:row nowrap;
      align-items:center;
      padding:.62em .62em .62em 1.62em;
      border-radius:5px;
      color:white;
      svg{
        margin-left:.62em;
      }
    }
  `
)

const StyledTop = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 5% 0;
  h2 {
    font-weight: bold;
    color: gray;
    text-decoration: underline;
    padding-bottom: 0.32em;
  }
  p {
    color: gray;
  }
`

const PrivacyPolicy = () => {
  return (
    <Layout>
      <StyledTop>
        <h2>プライバシーポリシー</h2>
        <p>個人情報保護方針についてお伝えいたします。</p>
      </StyledTop>
      <FAQdiv>
        <h4>個人情報保護方針</h4>
        <p>
          株式会社フランソアは、個人情報保護の重要性を認識し、個人情報に関する法律等を遵守するとともに、以下の方針に基づき個人情報の管理及び保護に努めます。
          なお、このプライバシーポリシーに記述している内容と個人情報保護法をあわせて、株式会社フランソアのプライバシーポリシーとします。
        </p>
        <div className="faqSection">
          <h5>個人情報の収集について</h5>
          <div className="answerSection">
            <p>
              株式会社フランソアは、次の場合に、個人情報を収集することがあります。
            </p>
            <ul>
              <li>キャンペーンや懸賞・イベントへご応募・ご参加された場合</li>
              <li>アンケートへご協力していただいた場合</li>
              <li>お問い合わせをいただいた場合</li>
              <li>電子メール配信サービスへ登録された場合</li>
              <li>その他上記に関係する事項</li>
            </ul>
            <p>
              株式会社フランソアがお客様の個人情報を収集する場合には、その目的を限定し、適法かつ公正な手段により行います。収集する個人情報の範囲は、収集の目的の達成に必要な限度を超えない範囲のものとします。また、株式会社フランソアは、個人情報をお客様以外から収集するときは、お客様の同意を得るものとします。
            </p>
          </div>
          <h5>個人情報の管理および保護について</h5>
          <div className="answerSection">
            <p>
              株式会社フランソアがお客様の個人情報を収集する場合には、その目的を限定し、適法かつ公正な手段により行います。収集する個人情報の範囲は、収集の目的の達成に必要な限度を超えない範囲のものとします。また、株式会社フランソアは、個人情報をお客様以外から収集するときは、お客様の同意を得るものとします。
            </p>
          </div>
          <h5>個人情報の利用について</h5>
          <div className="answerSection">
            <p>
              株式会社フランソアが収集した個人情報は、お客様の権利に十分配慮し、以下の目的のためにのみ利用します。
            </p>
            <ul>
              <li>
                キャンペーンやイベントへのご応募・ご参加による当選品をお届けするため
              </li>
              <li>アンケートへのご回答による当選品・謝礼をお届けするため</li>
              <li>お問い合わせに対するお返事をお届けするため</li>
              <li>希望された電子メール配信サービスのメールを配信するため</li>
              <li>
                マーケティングや商品開発などの統計的データとして利用するため
              </li>
              <li>
                その他、何らかの理由で、お客様と連絡を取る必要が生じたときのため
              </li>
              <li>お客様のお役にたつであろうお知らせをお届けするため</li>
            </ul>
          </div>
          <h5>個人情報の第三者提供について</h5>
          <div className="answerSection">
            <p>
              株式会社フランソアは、お客様の個人情報を、お客様ご自身の同意がない場合、業務委託先以外の第三者に開示・提供することはありません。ただし、
              <br />
              （1）お客様ご自身の同意がある場合
              <br />
              （2）法令により開示を求められた場合
              <br />
              （3）裁判所、警察等の公的機関から開示を求められた場合
              <br />
              には、個人情報を開示・提供することがあります。
            </p>
          </div>

          <h5>個人情報の開示、訂正、利用停止および削除について</h5>
          <div className="answerSection">
            <p>
              株式会社フランソアは、お客様から自らの個人情報の利用目的の通知及び開示、訂正、利用停止及び削除のお申し出があった場合には、第三者によるお客様の個人情報の改ざんを防止するため、その請求がお客様ご本人によるものであることが確認できた後、合理的な範囲内で必要な対応をいたします。
              <br />
              ただし、他のお客様の生命、身体、財産その他の権利利益を害するおそれがある場合、弊社の業務の適正な実施に支障を及ぼすと弊社が判断した場合、および開示することが他の法令に違反することとなる場合は、開示をお断りすることがあります。
            </p>
          </div>

          <h5>社内体制について</h5>
          <div className="answerSection">
            <p>
              株式会社フランソアはこのプライバシーポリシーに基づき、個人情報の保護に関する社内規程を制定し、弊社の役員・従業員に対し、個人情報の取扱いについて明確な方針を示し、お客様の個人情報の保護に努めます。
            </p>
          </div>

          <h5>プライバシーポリシーの改善について</h5>
          <div className="answerSection">
            <p>
              株式会社フランソアは、お客様の個人情報を保護するために、このプライバシーポリシーを改定することがあります。改定後は、このページに掲載し、新方針を適用いたします。改定にあたり個々にお客様に通知することはありませんので、随時このページをご確認いただきますようお願いいたします。
            </p>
          </div>

          <h5>お問い合わせ</h5>
          <div className="answerSection">
            <p>
              お客様の個人情報に関するお問合せおよび開示請求等については、下記にて受け付けております。
            </p>
          </div>

          <p style={{ textAlign: "right", marginTop: "20px" }}>
            制定日：平成16年12月20日
            <br />
            最終更新日：平成16年12月20日
          </p>
        </div>
      </FAQdiv>
      <BottomLinks>
        <Link to="/inquiry/">
          お問合せフォームへ
          <IconContext.Provider value={{ color: "white" }}>
            <BiRightArrowAlt />
          </IconContext.Provider>
        </Link>
      </BottomLinks>
    </Layout>
  )
}

export default PrivacyPolicy
